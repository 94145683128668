import { useCallback, useState } from "react";

import { IPromotion, fetchPromotion } from "../../../../../../services/api/promotion";
import { useBookingCheckoutContext } from "../../provider";

type UsePromotion = (props: {
  treatments: string[];
  urn: string;
}) => {
  fetchPromo: (code: string) => Promise<IPromotion | undefined>,
  isLoading: boolean;
  error?: string;
}

export const usePromotion: UsePromotion = ({ treatments, urn }) => {
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const { fetchCreditBalance } = useBookingCheckoutContext();

  const fetchPromo = useCallback(async (code: string) => {
    setIsLoading(true);
    setError(undefined);

    try {
      const promo = await fetchPromotion(code, treatments, urn);

      if (promo.promotionType === "VOUCHER") {
        await fetchCreditBalance();
      } else {
        return promo;
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(e.message);
        console.error('Cannot load promo code', e.message);
      }
      return undefined;
    } finally {
      setIsLoading(false);
    };
  }, [treatments, urn]);

  return {
    fetchPromo,
    isLoading,
    error,
  }
};
