import * as React from "react";

import {
  noNavPages,
  isPaymentPage,
  isBackgroundWhite,
} from "../../../utils/nav-layout";

import { FullFooter } from "./full-footer";
import { SimpleFooter } from "./simple-footer";
import { PaymentFooter } from "../../themed-v2/pages/payment-request-page/components/payment-footer";

interface IProps {
  location: string;
}

const Footer: React.FC<IProps> = ({ location }) => {
 if (isPaymentPage(location)) {
    return <PaymentFooter />;
  } else if (noNavPages(location)) {
    return <SimpleFooter isWhite={isBackgroundWhite(location)} />;
  } else {
    return <FullFooter />;
  }
};

export { Footer };
