import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { IconSpinner } from "../icons/spinner";

const ButtonContainer = styled.div`
  width: 100%;
`;

const IconContainer = styled.div`
  position: absolute;
  display: flex;
`;

const ButtonInnerContainer = styled.button<{ isClicked: boolean, disabled: boolean }>`
  display: flex;
  align-items: center;
  height: 43px;
  padding: ${({ theme }) => theme.spacing.size10};
  gap: ${({ theme }) => theme.spacing.size10};
  align-self: stretch;
  width: 100%;
  background-color: ${({ theme, isClicked }) =>
    isClicked ? theme.colours.mediumGrey2 : "transparent"};
  border: 1px solid ${({ theme }) => theme.colours.black};
  border: ${({ theme, isClicked }) =>
    `1px solid ${isClicked ? theme.colours.mediumGrey2 : theme.colours.black}`
  };
  border-radius: 4px;
  cursor: ${({ disabled }) => disabled ? "not-allowed" : "pointer"};
`;

const TextContainer = styled.div`
  width: 100%;
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size16};
`;

const Loading = styled(IconSpinner)`
  margin-top: ${({ theme }) => theme.spacing.size2};
`;

interface Props {
  id?: string;
  title: string;
  icon: React.ReactNode;
  onClick(): void;
  shouldShowLoadingIndicator?: boolean;
  disabled?: boolean;
}

export const IconButton = ({
  id,
  title,
  icon,
  onClick,
  shouldShowLoadingIndicator,
  disabled = false,
}: Props): JSX.Element => {
  const [isClicked, setClicked] = useState(false);

  const handleClick = useCallback(() => {
    onClick();
  }, [onClick]);

  useEffect(() => {
    setClicked(shouldShowLoadingIndicator === true);
  }, [shouldShowLoadingIndicator]);

  return (
    <ButtonContainer id={id}>
      <ButtonInnerContainer 
        onClick={handleClick}
        isClicked={isClicked}
        disabled={disabled || shouldShowLoadingIndicator === true}
      >
        <IconContainer>{icon}</IconContainer>
        <TextContainer>
          {isClicked || shouldShowLoadingIndicator ? (
            <Loading />
          ) : (
            title
          )}
        </TextContainer>
      </ButtonInnerContainer>
    </ButtonContainer>
  );
}