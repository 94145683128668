import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";

const HeaderHeightContext = createContext<number>(0);

export const HeaderHeightProvider = ({ children }: { children: ReactNode }) => {
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    const height = document.querySelector("header")?.offsetHeight ?? 0;
    setHeaderHeight(height);

    const handleResize = () => {
      const updatedHeight = height ?? 0;
      setHeaderHeight(updatedHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <HeaderHeightContext.Provider value={headerHeight}>
      {children}
    </HeaderHeightContext.Provider>
  );
};

export const useHeaderHeight = (): number => {
  const context = useContext(HeaderHeightContext);

  if (context === undefined) {
    throw new Error(
      "HeaderHeightContext must be used within a HeaderHeightProvider",
    );
  }

  return context;
};
