import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

import * as analytics from "../../analytics";
import { BookingCancelationModal } from "../../components/molecules";
import { Modal } from '../../components/themed-v2/molecules/modal';
import { BookingAccount, BookingHistory } from "../../components/organisms";
import { RateModalContainer } from "../../containers/booking/rate-modal";
import { LoaderContainer } from "../../containers/common";
import {
  cancelBooking,
  fetchCompletedForRating,
  fetchUsersBookings,
  IApiBooking,
  IBooking,
  saveRating,
} from "../../services/api/booking";
import { ILoginUser } from "../../services/api/customer";
import { fetchUser } from "../../services/api/user";
import { removeAuthToken } from "../../services/token-storage";
import { getCreditBalance } from "../../store/checkout/actions";
import { IRootState } from "../../store/index";
import { actionCreators as userActions } from "../../store/user/actions";
import { deNormalizeArray } from "../../store/utils/deNormalizeArray";
import { IAppConfiguration } from "../../services/api/configuration";
import { fetchAppConfiguration } from "../../store/configuration/actions";

/**
 * Booking action for modal window
 */
export enum BookingAction {
  cancel = "cancel",
  rate = "rate",
}

interface IReduxProps {
  creditBalance?: number;
  isLoggedIn: boolean;
  user?: ILoginUser;
  configuration?: IAppConfiguration;
}

interface IState {
  bookings: IBooking;
  isBookingLoading: boolean;
  modalError?: string;
  selectedBooking?: IApiBooking;
  showCancelConfirmation: boolean;
  showRateModal: boolean;
  showMapModal: boolean;
  w3wAddress: string;
}

type Props = IReduxProps & { dispatch: Dispatch<any> };

const W3WMap = styled.iframe`
  width: 800px;
  height: 500px;
  border: 0;
`;

class AccountContainer extends React.Component<Props, IState> {
  public state: IState = {
    bookings: { upcoming: [], completed: [] },
    isBookingLoading: false,
    modalError: undefined,
    selectedBooking: undefined,
    showCancelConfirmation: false,
    showRateModal: false,
    showMapModal: false,
    w3wAddress: "",
  };

  // tslint:disable-next-line: completed-docs
  private async initBookings() {
    try {
      this.setState({ isBookingLoading: true });
      const bookings = await fetchUsersBookings();
      const bookingsToRate = await fetchCompletedForRating();
      const unratedBookings = deNormalizeArray(bookingsToRate, "urn");

      bookings.completed.forEach((booking) => {
        if (unratedBookings[booking.urn]) {
          booking.canRate = true;
        }
      });

      this.setState({ bookings, isBookingLoading: false });
    } catch (e) {
      this.setState({ isBookingLoading: false });
    }
  }

  public async componentDidMount() {
    if (this.props.isLoggedIn) {
      await this.initBookings();

      this.props.dispatch(getCreditBalance());

      try {
        const userData: ILoginUser = await fetchUser();

        if (userData) {
          this.props.dispatch(userActions.setUserFields(userData));
        }
      } catch (err) {
        console.log(`Error: ${err}`);
      }
    }
  }

  public bookingAction = (booking: IApiBooking, action: BookingAction) => {
    this.setState({ selectedBooking: booking }, () => {
      switch (action) {
        case BookingAction.cancel:
          this.setState({ showCancelConfirmation: true });
          break;

        case BookingAction.rate:
          this.setState({ showRateModal: true });
          break;

        default:
          return;
      }
    });
  };

  public closeConfirmationModal = () => {
    this.setState({ showCancelConfirmation: false });
  };

  public closeRateModal = () => {
    this.setState({ showRateModal: false });
  };

  public openMapModal = (w3wAddress: string) => {
    this.setState({
      showMapModal: true,
      w3wAddress,
    });
  };

  public closeMapModal = () => {
    this.setState({
      showMapModal: false,
    });
  };

  public cancelBooking = async () => {
    if (this.state.selectedBooking) {
      try {
        await cancelBooking(this.state.selectedBooking.urn);
        // tslint:disable-next-line: no-floating-promises
        this.initBookings();
        this.closeConfirmationModal();

        analytics.track({
          name: analytics.AnalyticsEvents.BOOKING_CANCEL,
          payload: {
            bookingUrn: this.state.selectedBooking.urn,
          },
        });
      } catch (e) {
        if (e instanceof Error) {
          this.closeConfirmationModal();
          this.setState({ modalError: e.message });

          analytics.track({
            name: analytics.AnalyticsEvents.BOOKING_CANCEL,
            payload: {
              bookingUrn: this.state.selectedBooking.urn,
            },
          });
        }
      }
    }
  };

  public rateBooking = async (urn: string, rate: number, comment: string) => {
    try {
      await saveRating(urn, rate, comment);
      // tslint:disable-next-line: no-floating-promises
      this.initBookings();
      this.closeRateModal();
    } catch (e) {
      if (e instanceof Error) {
        this.closeRateModal();
        this.setState({ modalError: e.message });
      }
    }
  };

  public logout = async () => {
    analytics.reset();
    // tslint:disable-next-line: await-promise
    await this.props.dispatch(userActions.logoutUser());
    // tslint:disable-next-line: await-promise
    await this.props.dispatch(getCreditBalance() as any);
    // tslint:disable-next-line: await-promise
    await this.props.dispatch(fetchAppConfiguration());
    removeAuthToken();
  };

  public clearError = () => {
    this.setState({ modalError: undefined });
  };

  /**
   * Render
   */
  public render() {
    if (!this.props.isLoggedIn) {
      return <Redirect to={"/login"} />;
    }

    return (
      <React.Fragment>
        {this.props.user && typeof this.props.creditBalance !== "undefined" && (
          <BookingAccount
            onLogout={this.logout}
            user={this.props.user}
            creditBalance={this.props.creditBalance}
          />
        )}
        {this.state.isBookingLoading ? (
          <LoaderContainer type="block" />
        ) : (
          <BookingHistory
            bookings={this.state.bookings}
            onMapOpen={this.openMapModal}
            onBookingSelect={this.bookingAction}
          />
        )}
        {this.state.selectedBooking && (
          <>
            <RateModalContainer
              booking={this.state.selectedBooking}
              isShow={this.state.showRateModal}
              onSubmit={this.rateBooking}
              onCancel={this.closeRateModal}
            />
            {this.props.configuration && (
              <BookingCancelationModal
                booking={this.state.selectedBooking}
                isShow={this.state.showCancelConfirmation}
                configuration={this.props.configuration}
                onApprove={this.cancelBooking}
                onCancel={this.closeConfirmationModal}
              />
            )}
          </>
        )}
        <Modal
          isShow={Boolean(this.state.modalError)}
          onClose={this.clearError}
          modalText={this.state.modalError}
        />
        <Modal
          isShow={Boolean(this.state.showMapModal)}
          onClose={this.closeMapModal}
          title=""
        >
          <W3WMap src={`https://w3w.co/${this.state.w3wAddress}`}></W3WMap>
        </Modal>
      </React.Fragment>
    );
  }
}

// store
const mapStateToProps = (state: IRootState): IReduxProps => {
  return {
    creditBalance: state.checkoutState.creditBalance,
    isLoggedIn: state.userState.isLogedIn,
    user: state.userState.user,
    configuration: state.configurationState.configuration,
  };
};

const store =
  connect<IReduxProps, {}, {}, IRootState>(mapStateToProps)(AccountContainer);

export { store as AccountContainer };
