import React from "react";
import styled from "styled-components";

import { device } from "../../../../../../utils";
import { useHeaderHeight } from "../../../../../../providers/header-height-provider";

const TabsContainer = styled.div<{ topOffset: number }>`
  position: sticky;
  width: 100%;
  height: 52px;
  top: ${({ topOffset }) => `${topOffset}px`};
  left: 0;
  z-index: 100;
  background-color: ${({ theme }) => theme.colours.background.white};
  border-top-left-radius: ${({ theme }) => theme.type.sizes.size8};
  border-top-right-radius: ${({ theme }) => theme.type.sizes.size8};
  border-bottom: ${({ theme }) => `1px solid ${theme.colours.ruubyLightGrey}`};
  display: flex;
  align-items: center;
`;

const TabSpan = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 600;
  color: ${({ theme }) => theme.colours.tab.textActive};
  background: none;
  border: none;
  text-align: left;
  align-items: center;
  padding: 18px 20px;

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
    padding: 16px 28px;
  }
`;

export const SwitzerlandTableTab = (): JSX.Element => {
  const headerHeight = useHeaderHeight();

  return (
    <TabsContainer topOffset={headerHeight} id="therapist-tabs">
      <TabSpan>Services</TabSpan>
    </TabsContainer>
  );
};
