import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { ApiError } from "../../../../../../services/api/types/graphql";
import { ContactMethodType, AuthScreenType } from "../..";
import { Auth } from "../../components/templates/auth";
import { useSignInWithGoogle } from "../../hooks/use-sign-in-with-google";

interface AuthPageProps {
  screenType: AuthScreenType,
  contactMethod: ContactMethodType,
  onCompleteLogin: () => void,
}

export const AuthPage = ({ screenType, contactMethod, onCompleteLogin }: AuthPageProps): JSX.Element => {
  const params = useParams();
  const history = useHistory();
  const [error, setError] = useState<ApiError>();
  const [contact, setContact] = useState(params.contact ?? "");

  const handleChangeContact = useCallback((contact: string) => {
    setContact(contact);
  }, []);

  const onValidateNewContact = useCallback((contactToken: string) => {
    history.replace(`/signup/email/oauth/register/${contactToken}`);
  }, [contactMethod, contact]);

  const {
    isLoading: isGoogleLoginLoading,
    error: googleLoginError,
    initiateSignIn: initiateGoogleSignIn,
  } = useSignInWithGoogle({
    onSuccess: onCompleteLogin,
    onValidateNewContact,
  });

  useEffect(() => {
    setError(
      googleLoginError ||
      undefined,
    );
  }, [
    googleLoginError,
  ]);

  useEffect(() => {
    setContact("");
  }, [contactMethod]);

  return (
    <Auth
      screenType={screenType}
      contact={contact}
      error={error}
      contactMethod={contactMethod}
      onChangeContact={handleChangeContact}
      isGoogleSignInLoading={isGoogleLoginLoading}
      onClickSignInWithGoogle={initiateGoogleSignIn}
    />
  );
}
