import React, { useMemo } from "react";
import styled from "styled-components";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { ContactMethod, ContactMethodType } from "../../..";
import { usePhoneOtpRequest } from "../../../hooks/use-phone-otp-request";
import { useEmailOtpRequest } from "../../../hooks/use-email-otp-request";
import { Error } from "../../atoms/error";
import { Button } from "../../atoms/button";

const StyledSection = styled('div')`
  width: 100%;
`;

interface RequestOtpButtonProps {
  contactMethod: ContactMethodType,
  contact: string,
  disabled: boolean,
  children: React.ReactNode,
}

export const RequestOtpButton = ({ contactMethod, contact, disabled, children } : RequestOtpButtonProps): JSX.Element => {
  const history = useHistory();

  const onSendOtp = useCallback((token: string): void => {
    history.replace(`/auth/${contactMethod.toLowerCase()}/${contact}/${token}`);
  }, [contactMethod, contact]);

  const {
    isLoading: isPhoneOtpLoading,
    initiateOtpRequest: initiatePhoneOtpRequest,
    error: phoneOtpError,
  } = usePhoneOtpRequest({ onSuccess: onSendOtp });

  const {
    isLoading: isEmailOtpLoading,
    initiateOtpRequest: initiateEmailOtpRequest,
    error: emailOtpError,
  } = useEmailOtpRequest({ onSuccess: onSendOtp });  

  const requestNewOtp = useCallback((): void => {
    if (contactMethod === ContactMethod.PHONE) {
      initiatePhoneOtpRequest(contact);
    }
    else {
      initiateEmailOtpRequest(contact);
    }
  }, [contact, contactMethod]);

  const otpError = useMemo((): string | undefined => 
    contactMethod === ContactMethod.PHONE
    ? phoneOtpError
    : emailOtpError
  , [contactMethod, emailOtpError, phoneOtpError]);

  const isOtpLoading = useMemo((): boolean => 
    contactMethod === ContactMethod.PHONE
    ? isPhoneOtpLoading
    : isEmailOtpLoading
  , [contactMethod, isEmailOtpLoading, isPhoneOtpLoading]);  

  return (
    <StyledSection>
      {otpError && (
        <Error capitalize>{otpError.replaceAll('_', ' ')}</Error>
      )}
      <Button 
        size="small" 
        onClick={requestNewOtp} 
        disabled={disabled} 
        isLoading={isOtpLoading}
      >
        {children}
      </Button>
    </StyledSection>
  )
}

RequestOtpButton.defaultProps = {
  disabled: false,
};
