import React from "react";
import styled from "styled-components";

import { TierLabel } from "../../../../atoms/tier-label";
import { TherapistTierType } from "../../../../../../services/api/types/graphql";
import { device } from "../../../../../../utils";
import { Container } from "react-grid-system";

const TierListContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  gap: ${({ theme }) => theme.type.sizes.size20};

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

const TierContainer = styled.li`
  display: flex;
  align-items: center;
  max-width: 373px;
  gap: ${({ theme }) => theme.type.sizes.size14};
`;

const TierNameText = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 400;
  color: ${({ theme }) => theme.colours.textGrey};
  margin: 0;

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
`;

interface Props {
  tierList: [TherapistTierType, string][];
}

export const SwitzerlandTherapistTierList = ({
  tierList,
}: Props): JSX.Element => (
  <TierListContainer>
    {tierList.map(([tier, name]) => (
      <TierContainer key={tier}>
        <TierLabel type={tier} width={44} height={44} fontSize={25} />
        <TierNameText>{name}</TierNameText>
      </TierContainer>
    ))}
  </TierListContainer>
);
