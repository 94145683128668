// Based on https://developers.google.com/identity/gsi/web/reference/js-reference
import { useCallback, useEffect, useState } from "react";

import { config } from "../../../../../../config";
import * as analytics from "../../../../../../analytics";
import { LoginFlowType } from "../..";
import { useSaveAccessToken } from "../../hooks/use-save-access-token";
import { ApiError, useSignInWithGoogleMutation } from "../../../../../../services/api/types/graphql";

type UseSignInWithGoogle = (params: {
  onSuccess(): void;
  onValidateNewContact(token: string): void;
}) => {
  isLoading: boolean;
  error?: ApiError;
  initiateSignIn(): void;
};

export const useSignInWithGoogle: UseSignInWithGoogle = ({
  onSuccess,
  onValidateNewContact,
}) => {
  const [isInProgress, setIsInProgress] = useState(false);
  const [error, setError] = useState<ApiError>();
  const saveAccessToken = useSaveAccessToken();

  const requestUserDetails = useCallback((response: google.accounts.id.CredentialResponse) => {
    try {
      signInWithGoogle({
        variables: {
          identityToken: response.credential,
        },
      });
    } catch (error) {
      console.error("Google login failed:", JSON.stringify(error));
      setError(ApiError.GENERAL_ERROR);
      logGoogleAnalyticsError();
    } finally {
      setIsInProgress(false);
    }
  }, []);

  useEffect(() => {
    google.accounts.id.initialize({
      client_id: config.google.webClientId,
      callback: requestUserDetails,
      itp_support: true,
      ux_mode: "popup",
      auto_select: true,
      cancel_on_tap_outside: true,
    });
    const button = document.getElementById("google-sso-button");
    if (button) {
      google.accounts.id.renderButton(
        button,
        {
          text: "continue_with",
          type: "standard",
          shape: "rectangular",
          theme: "outline",
          size: "large",
          logo_alignment: "left",
          locale: "en-GB",
          width: 343,
          click_listener: () => analytics.track({
              name: analytics.AnalyticsEvents.LOGIN_GOOGLE_CLICKED,
          }),
         }
      );  
    }
  }, []);

  const logGoogleAnalyticsError = useCallback(async (): Promise<void> => {
    analytics.track({
      name: analytics.AnalyticsEvents.LOGIN_GOOGLE_FAILED,
    });
  }, []);

  const [signInWithGoogle, { loading }] = useSignInWithGoogleMutation({
    onCompleted: async ({ signInWithGoogle: data }) => {
      switch (data.__typename) {
        case "AuthTokens": {
          await saveAccessToken(data.accessToken, LoginFlowType.GOOGLE);
          onSuccess();
          break;
        }

        case "ContactVerificationToken": {
          if (data.verifiedContactToken) {
            onValidateNewContact(data.verifiedContactToken);
          }
          break;
        }

        case "RuubyGraphError": {
          console.error("Google signin backend error:", data.error);
          await logGoogleAnalyticsError();
          setError(data.error);
          setIsInProgress(false);
          break;
        }
      }
    },
    onError: async (err) => {
      console.error("Google signin backend error:", err.message);
      await logGoogleAnalyticsError();
      setError(ApiError.GENERAL_ERROR);
      setIsInProgress(false);
    },
    errorPolicy: "all",
  });

  const loginWithGoogle = useCallback(() =>{
    google.accounts.id.prompt((notification) => {
      if (notification.getDismissedReason() === "cancel_called" || 
          notification.getMomentType() === "skipped" || 
          notification.getMomentType() === "dismissed" ||
          notification.getNotDisplayedReason() === "suppressed_by_user"
      ) {
        analytics.track({
          name: analytics.AnalyticsEvents.LOGIN_GOOGLE_CANCELLED,
        });
        setIsInProgress(false);
      }
    });
  }, [signInWithGoogle, logGoogleAnalyticsError]);

  const initiateSignIn = useCallback(async (): Promise<void> => {
    setIsInProgress(true);
    setError(undefined);

    analytics.track({
      name: analytics.AnalyticsEvents.LOGIN_GOOGLE_CLICKED,
    });

    try {
      loginWithGoogle();
    } catch (err) {
      console.error("Google login failed:", JSON.stringify(err));
      setError(ApiError.GENERAL_ERROR);
      logGoogleAnalyticsError();
    }
  }, [signInWithGoogle, logGoogleAnalyticsError]);

  return {
    isLoading: isInProgress || loading,
    error,
    initiateSignIn,
  };
};
