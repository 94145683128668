import * as analytics from "../../analytics";
import favicon from "../../assets/images/favicon.png";
import { Footer } from "../../components/organisms";
import { ScrollTop } from "../../components/utils";
import { HeaderContainer, LoaderContainer } from "../../containers/common";
import { ICategory } from "../../services/api/category-list";
import { IApiMarketingMessage } from "../../services/api/notifications";
import { fetchUser } from "../../services/api/user";
import { getAuthToken, removeAuthToken } from "../../services/token-storage";
import { fetchCategoryList } from "../../store/categories/actions";
import { fetchAppConfiguration } from "../../store/configuration/actions";
import { IRootState } from "../../store/index";
import { fetchMarketingMessage } from "../../store/marketing-message/actions";
import { actionCreators as userActions } from "../../store/user/actions";
import { theme, newTheme } from "../../theme";
import * as React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { ScreenClassProvider, setConfiguration } from "react-grid-system";
import { QueryClient, QueryClientProvider } from "react-query";
import WebFont from "webfontloader";

import { CookieBanner } from "../../components/themed-v2/molecules/cookie-banner";
import { appRoutes } from "../../routes/index";
import {
  device,
  isBackgroundWhite,
  isBackgroundWhiteOnlyMobile,
  isPaymentPage,
  isVerticallyAligned,
  isWebViewPage,
  width,
} from "../../utils";
import { RouterHistoryProvider } from "../../providers/router-history-provider";
import { HeaderHeightProvider } from "../../providers/header-height-provider";

WebFont.load({
  custom: {
    families: ["bigcaslon_romregular"],
    urls: ["/fonts/fonts.css"],
  },
  typekit: {
    id: "sky4bud",
  },
});

setConfiguration({
  maxScreenClass: "xl",
  breakpoints: [width.mobileL, width.tablet, width.laptop, width.desktop],
});

interface IState {
  isReady: boolean;
}

interface IReduxProps {
  categories: ICategory[];
  isCategoriesLoading: boolean;
  marketingMessage: IApiMarketingMessage;
  marketingMessageLoading: boolean;
}

interface IAnimationLocation {
  pathname: string;
}

type Props = IReduxProps &
  RouteComponentProps<{
    categoryName: string;
    therapistId: string;
  }> & { dispatch: Dispatch<any> };

const AppLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
`;

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const MainWrapper = styled.div<IAnimationLocation>`
  flex: 1 0 auto;
  background-color: ${({ pathname }) =>
    isPaymentPage(pathname) || isBackgroundWhite(pathname)
      ? newTheme.colours.background.white
      : theme.color.ruubyGreyBg};
  ${({ pathname }) => setWrapperAnimation(pathname)};
  ${({ pathname }) =>
    isBackgroundWhiteOnlyMobile(pathname)
      ? `
    @media ${device.tablet} {
      background-color: ${theme.color.ruubyGreyBg};
    }`
      : undefined};
  ${({ pathname }) =>
    isVerticallyAligned(pathname)
      ? `
    @media ${device.tablet} {
      display: flex;
      align-items: center;
      justify-content: center;
    }`
      : undefined};
`;

const CookieBannerWrapper = styled.div<IAnimationLocation>`
  display: ${({ pathname }) =>
    isVerticallyAligned(pathname) ? `contents` : `flex`};
  justify-content: center;
  align-items: center;
`;

const queryClient = new QueryClient();

/**
 * Main container for app with theme provdier
 * and routing
 */
class App extends React.Component<Props, IState> {
  public state = {
    isReady: false,
  };

  public async componentDidMount() {
    // tslint:disable-next-line: await-promise
    await this.props.dispatch(fetchAppConfiguration());
    await this.getCategories();
    await this.getMarketingMessage();

    const userToken = await getAuthToken();

    if (userToken) {
      try {
        const userData = await fetchUser();
        analytics.identify(userData.urn, {
          email: userData.email,
          firstName: userData.firstName,
          lastName: userData.lastName,
        });
        // tslint:disable-next-line: await-promise
        await this.props.dispatch(userActions.loginUser(userData));
      } catch {
        this.props.dispatch(userActions.logoutUser());
        removeAuthToken();
      } finally {
        this.setState({ isReady: true });
      }
    } else {
      this.setState({ isReady: true });
    }
  }

  /**
   * Fetch treatment's categories
   */
  private async getCategories() {
    if (!this.props.categories.length && !this.props.isCategoriesLoading) {
      await this.props.dispatch(fetchCategoryList());
    }
  }

  /**
   * Fetch marketing message
   */
  private async getMarketingMessage() {
    if (this.props.marketingMessageLoading) {
      return;
    }
    await this.props.dispatch(fetchMarketingMessage());
  }

  /**
   * Main theme for styled components
   * Main Top Level Routes (Routing)
   */
  public render(): JSX.Element {
    const currentLocation = this.props.location.pathname;

    return (
      <ThemeProvider theme={newTheme}>
        <QueryClientProvider client={queryClient}>
          <ScreenClassProvider>
            <RouterHistoryProvider>
              <HeaderHeightProvider>
                <ScrollTop>
                  <AppWrapper>
                    <Helmet>
                      <link rel="shortcut icon" href={favicon} />
                    </Helmet>
                    {!isWebViewPage(currentLocation) && <HeaderContainer />}
                    {this.state.isReady ? (
                      <MainWrapper pathname={currentLocation}>
                        {appRoutes(
                          this.props.history,
                          this.props.configuration,
                        )}
                        <CookieBannerWrapper pathname={currentLocation}>
                          <CookieBanner />
                        </CookieBannerWrapper>
                      </MainWrapper>
                    ) : (
                      <AppLoaderWrapper>
                        <LoaderContainer type="block" />
                      </AppLoaderWrapper>
                    )}
                    {!isWebViewPage(currentLocation) && (
                      <Footer location={currentLocation} />
                    )}
                  </AppWrapper>
                </ScrollTop>
              </HeaderHeightProvider>
            </RouterHistoryProvider>
          </ScreenClassProvider>
        </QueryClientProvider>
      </ThemeProvider>
    );
  }
}

/**
 * Switch transitions bases on url
 * @param path url path
 */
function setWrapperAnimation(path: string) {
  if (
    path === "/" ||
    path.includes("mobile-beauty/") ||
    path.includes("category/") ||
    path.includes("book/")
  ) {
    // inplace fade transition
    return `.pageTransition-appear,
    .pageTransition-enter {
      opacity: 0;
    }

    .pageTransition-appear-active,
    .pageTransition-enter-active {
      opacity: 1;
      transition: all ${theme.transition.easeInOut04};
    }

    .pageTransition-exit {
      position: absolute;
      top: 0;
      width: 100%;
      opacity: 1;
      transition: all ${theme.transition.easeInOut02};
    }

    .pageTransition-exit-active {
      opacity: 0;
    }`;
  } else {
    // slide fade transition
    return `.pageTransition-appear,
    .pageTransition-enter {
      opacity: 0;
      transform: translate(50px, 0);
    }

    .pageTransition-appear-active,
    .pageTransition-enter-active {
      opacity: 1;
      transform: translate(0, 0);
      transition: all ${theme.transition.easeInOut04};
      transition-timing-function: cubic-bezier(0.5, -0.5, 0.5, 1.5);
    }

    .pageTransition-exit {
      position: absolute;
      top: 0;
      width: 100%;
      opacity: 0;
      transform: translate(-50px, 0);
      transition: all ${theme.transition.easeInOut02};
    }

    .pageTransition-exit-active {
      opacity: 0;
    }`;
  }
}

// store
const mapStateToProps = (state: IRootState) => {
  return {
    categories: state.categoriesState.categories,
    isCategoriesLoading: state.categoriesState.isCategoriesLoading,
    marketingMessage: state.marketingMessageState.marketingMessage,
    marketingMessageLoading:
      state.marketingMessageState.marketingMessageLoading,
    configuration: state.configurationState.configuration,
  };
};

const routedApp = withRouter(connect(mapStateToProps)(App));

export { routedApp as App };
