import React, { useCallback } from "react";

import { Card, Heading1, Paragraph } from "../../atoms";
import styled from "styled-components";
import { device } from "../../../../utils";
import { Container } from "react-grid-system";
import { TherapistTierType } from "../../../../services/api/types/graphql";
import { SwitzerlandTherapistTierList } from "./components/switzerland-therapist-tier-list";
import { SwitzerlandTreatmentTable } from "./components/switzerland-treatment-table";
import { SwitzerlandTableTab } from "./components/switzerland-service-tab";
import { useHandleTreatments } from "./hooks/use-handle-swiss-treatment";
import { useTreatmentData } from "./hooks/use-treatment-data";
import { CheckoutButtonContainer } from "./components/checkout-button";
import { useHeaderHeight } from "../../../../providers/header-height-provider";

const HeaderContainer = styled.section<{
  topOffset: number;
}>`
  top: ${({ topOffset }) => `-${topOffset + 18}px`};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${({ theme, topOffset }) =>
    `${topOffset + 22}px ${theme.spacing.size18} 0`};
  background-color: ${({ theme }) => theme.colours.lightGreen};
  z-index: 99;

  @media ${device.tablet} {
    padding-top: ${({ topOffset }) => `${topOffset + 18}px`};
    top: ${({ topOffset }) => `-${topOffset + 22}px`};
  }
`;

const MainContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.size20};
  @media ${device.laptop} {
    max-width: 750px !important;
    padding: 0 !important;
  }
`;

const HeadingStyled = styled(Heading1)`
  text-align: center;
  color: ${({ theme }) => theme.colours.white};
  margin-bottom: 0;
`;

const ParagraphStyled = styled(Paragraph)`
  color: ${({ theme }) => theme.colours.white};
  text-align: center;
`;

const BottomOffset = styled.div<{
  height: number;
}>`
  height: ${({ height }) => `${height}px`};
`;

const TherapistPortfolioContainer = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.size18};
`;

const PortfolioCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

interface SwitzerlandLandingPageProps {
  isWebView?: boolean;
}

export const SwitzerlandLandingPage = ({
  isWebView,
}: SwitzerlandLandingPageProps): JSX.Element => {
  const topOffset = useHeaderHeight();
  const { treatmentsData } = useTreatmentData();

  const { addedTreatments, handleAddTreatment, handleRemoveTreatment } =
    useHandleTreatments({
      treatments: treatmentsData.flatMap((grouped) => grouped.treatments) ?? [],
    });

  const redirectToFormWithDetails = useCallback(() => {
    //This will be get request with params in URL - treatmentUrn - quantity
  }, [addedTreatments]);

  return (
    <>
      <HeaderContainer topOffset={topOffset}>
        <HeadingStyled>Select your treatment</HeadingStyled>
        <ParagraphStyled>
          Browse and select your treatments from our menu below. Choose between
          our classic and elite therapist tiers.
        </ParagraphStyled>
        <BottomOffset height={24} />
      </HeaderContainer>

      <MainContainer>
        <SwitzerlandTherapistTierList
          tierList={[
            [
              TherapistTierType.CLASSIC,
              "Experienced and vetted professionals who deliver a consistently high level of service.",
            ],
            [
              TherapistTierType.ELITE,
              "An elevated experience and specialist within their field.",
            ],
          ]}
        />
        <BottomOffset height={12} />
        <TherapistPortfolioContainer>
          <PortfolioCard>
            <SwitzerlandTableTab />
            <SwitzerlandTreatmentTable
              treatmentsData={treatmentsData}
              onAddTreatment={handleAddTreatment}
              onRemoveTreatment={handleRemoveTreatment}
              addedTreatment={addedTreatments}
            />
          </PortfolioCard>
        </TherapistPortfolioContainer>
      </MainContainer>
      <BottomOffset height={24} />
      <CheckoutButtonContainer
        addedTreatments={addedTreatments}
        onCheckout={() => redirectToFormWithDetails()}
        isWebView={isWebView}
      />
    </>
  );
};
