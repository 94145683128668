import React from "react";
import styled from "styled-components";

import { ButtonProps, Button as V2Button } from "../../../../../atoms";
import { IconSpinner } from "../../../../../atoms/icons/spinner";

const RegularButton = styled(V2Button)`
  width: 100%;
  font-weight: bold;
  text-align: center;
`;

const LoadingButton = styled(RegularButton)`
  &:disabled {
    background-color: ${({ theme }) => theme.colours.mediumGrey2};
    padding: 5px 0px;
  }
`;

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, ButtonProps {
  isLoading?: boolean;
}

export const Button = (props: IProps) => {
  if (props.isLoading) {
    return (
      <LoadingButton {...props} disabled>
        <IconSpinner />
      </LoadingButton>
    );
  }
  return <RegularButton {...props} />;
};
