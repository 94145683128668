import React, { useMemo } from "react";
import styled from "styled-components";

import { device } from "../../../../../../utils";
import { AddedTreatment } from "../../hooks/use-handle-swiss-treatment";
import { FloatingCtaButton } from "../../../../molecules/floating-cta-button";

const CheckoutButtonOuterContainer = styled.div<{ isWebView?: boolean }>`
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: ${({ isWebView }) => (isWebView ? "16px" : " 75px")};
  margin-left: ${({ theme }) => theme.spacing.size18};
  margin-right: ${({ theme }) => theme.spacing.size18};
  z-index: 101;
  pointer-events: none;

  @media ${device.tablet} {
    margin-top: ${({ theme }) => theme.spacing.size24};
    bottom: 50px;
  }
`;

interface Props {
  isLoading?: boolean;
  isWebView?: boolean;
  onCheckout(): void;
  addedTreatments: AddedTreatment;
}

export const CheckoutButtonContainer = ({
  isLoading,
  onCheckout,
  addedTreatments,
  isWebView,
}: Props): JSX.Element => {
  const hasCustomerAddedTreatment = useMemo(() => {
    return Object.keys(addedTreatments).length > 0;
  }, [addedTreatments]);

  return (
    <>
      {hasCustomerAddedTreatment && (
        <CheckoutButtonOuterContainer isWebView={isWebView}>
          <FloatingCtaButton
            isDisabled={isLoading ?? false}
            onClick={onCheckout}
          />
        </CheckoutButtonOuterContainer>
      )}
    </>
  );
};
