import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { Route, Switch } from "react-router";
import { useHistory } from "react-router-dom";

import { MainTemplate } from "../../../templates/main";
import { metaData } from "../../../../static/meta";
import { AuthPage } from "./pages/auth";
import { ValidateOtpPage } from "./pages/validate-otp";
import { RegisterPage } from "./pages/register";
import { useSelector } from "react-redux";
import { IRootState } from "../../../../store";

/** Contact methods supported for sign in */
export enum ContactMethod {
  APPLE = "APPLE",
  EMAIL = "EMAIL",
  FACEBOOK = "FACEBOOK",
  GOOGLE = "GOOGLE",
  PHONE = "PHONE",
}

export enum AuthScreenType {
  LOGIN = "LOGIN",
  SIGNUP = "SIGNUP",
}

export type ContactMethodType = ContactMethod.EMAIL | ContactMethod.PHONE;

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export enum LoginFlowType {
  NEW = "NEW",
  EXISTING = "EXISTING",
  APPLE = "APPLE",
  FACEBOOK = "FACEBOOK",
  GOOGLE = "GOOGLE",
}

export const LoginPage: React.FC = () => {
  const isLoggedIn = useSelector<IRootState>(
    (state) => state.userState.isLogedIn,
  );
  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn) {
      onCompleteLogin();
    }
  }, []);

  const onCompleteLogin = () => {
    const redirectUrl = sessionStorage.getItem("redirectUrl");
    if (redirectUrl) {
      sessionStorage.removeItem("redirectUrl");
      history.replace(redirectUrl);
    } else {
      history.replace("/account");
    }
  };

  return (
    <MainTemplate>
      <Helmet>
        <title>{metaData.login.title}</title>
        <meta name="description" content={metaData.login.description} />
      </Helmet>
      <Switch>
        <Route
          exact
          path={"/login/phone/:contact?"}
          render={() => (
            <AuthPage
              screenType={AuthScreenType.LOGIN}
              contactMethod={ContactMethod.PHONE}
              onCompleteLogin={onCompleteLogin}
            />
          )}
        />
        <Route
          exact
          path={"/signup/phone/:contact?"}
          render={() => (
            <AuthPage
              screenType={AuthScreenType.SIGNUP}
              contactMethod={ContactMethod.PHONE}
              onCompleteLogin={onCompleteLogin}
            />
          )}
        />
        <Route
          exact
          path={"/auth/:method/:contact/:token"}
          render={() => <ValidateOtpPage onCompleteLogin={onCompleteLogin} />}
        />
        <Route
          exact
          path={"/signup/phone/:contact/register/:token"}
          render={() => (
            <RegisterPage
              contactMethod={ContactMethod.PHONE}
              onCompleteLogin={onCompleteLogin}
            />
          )}
        />
        <Route
          exact
          path={"/login/email/:contact?"}
          render={() => (
            <AuthPage
              screenType={AuthScreenType.LOGIN}
              contactMethod={ContactMethod.EMAIL}
              onCompleteLogin={onCompleteLogin}
            />
          )}
        />
        <Route
          exact
          path={"/signup/email/:contact?"}
          render={() => (
            <AuthPage
              screenType={AuthScreenType.SIGNUP}
              contactMethod={ContactMethod.EMAIL}
              onCompleteLogin={onCompleteLogin}
            />
          )}
        />
        <Route
          exact
          path={"/signup/email/:contact/register/:token"}
          render={() => (
            <RegisterPage
              contactMethod={ContactMethod.EMAIL}
              onCompleteLogin={onCompleteLogin}
            />
          )}
        />
      </Switch>
    </MainTemplate>
  );
};
