import { useCallback, useMemo, useState } from "react";
import jwtDecode, { JwtPayload } from "jwt-decode";

import {
  ApiError,
  useRegisterCustomerWithValidatedEmailMutation,
  useRegisterCustomerWithValidatedPhoneMutation,
} from "../../../../../../services/api/types/graphql";
import { useSaveAccessToken } from "../../hooks/use-save-access-token";
import * as analytics from "../../../../../../analytics";
import { ContactMethod, LoginFlowType } from "../..";

interface CustomerSubmitData {
  firstName: string;
  lastName: string;
  agreesToEmailMarketing: boolean;
  email?: string;
  phone?: string;
  preferredRegion?: string;
}

interface OtpVerifiedContact extends JwtPayload {
  method: ContactMethod;
  to: string;
  firstName?: string;
  lastName?: string;
}

type UseRegisterCustomer = (params: {
  contactToken: string;
  onSuccess(): void;
}) => {
  contactMethod: ContactMethod;
  to: string;
  customerFirstName?: string;
  customerLastName?: string;
  error?: ApiError;
  isLoading: boolean;
  registerCustomer(details: CustomerSubmitData): Promise<void>;
};

export const useRegisterCustomer: UseRegisterCustomer = ({
  contactToken,
  onSuccess,
}) => {
  const [error, setError] = useState<ApiError>();
  const saveAccessToken = useSaveAccessToken();

  const onSuccessfulRegistration = useCallback(
    async (token: string): Promise<void> => {
      analytics.track({
        name: analytics.AnalyticsEvents.LOGIN_FLOW_COMPLETED,
        payload: {
          userType: LoginFlowType.NEW,
        },
      });
      await saveAccessToken(token, LoginFlowType.NEW);
      onSuccess();
    },
    [onSuccess, saveAccessToken],
  );

  const [registerWithEmailToken, { loading: isLoadingWithEmailToken }] =
    useRegisterCustomerWithValidatedEmailMutation({
      onCompleted: async ({ registerCustomerWithValidatedEmail: data }) => {
        switch (data.__typename) {
          case "AuthTokens":
            await onSuccessfulRegistration(data.accessToken);
            break;
          case "RuubyGraphError":
            setError(data.error);
        }
      },
      onError: () => setError(ApiError.GENERAL_ERROR),
      errorPolicy: "all",
    });

  const [registerWithPhoneToken, { loading: isLoadingWithPhoneToken }] =
    useRegisterCustomerWithValidatedPhoneMutation({
      onCompleted: async ({ registerCustomerWithValidatedPhone: data }) => {
        switch (data.__typename) {
          case "AuthTokens":
            await onSuccessfulRegistration(data.accessToken);
            break;
          case "RuubyGraphError":
            setError(data.error);
        }
      },
      onError: () => setError(ApiError.GENERAL_ERROR),
      errorPolicy: "all",
    });

  const {
    method: contactMethod,
    to,
    firstName: customerFirstName,
    lastName: customerLastName,
  } = useMemo(
    () => jwtDecode<OtpVerifiedContact>(contactToken),
    [contactToken],
  );

  const registerCustomer: ReturnType<UseRegisterCustomer>["registerCustomer"] =
    async (details) => {
      setError(undefined);

      const { phone, email, firstName, lastName, agreesToEmailMarketing, preferredRegion } =
        details;

      switch (contactMethod) {
        case ContactMethod.EMAIL:
        case ContactMethod.GOOGLE:
        case ContactMethod.FACEBOOK: {
          if (!phone) {
            throw new Error("Phone number is required");
          }

          await registerWithEmailToken({
            variables: {
              firstName,
              lastName,
              phone,
              agreesToEmailMarketing,
              emailValidationToken: contactToken,
              preferredRegion,
            },
          });

          break;
        }

        case ContactMethod.PHONE: {
          if (!email) {
            throw new Error("Email is required");
          }

          await registerWithPhoneToken({
            variables: {
              firstName,
              lastName,
              email,
              agreesToEmailMarketing,
              phoneValidationToken: contactToken,
              preferredRegion,
            },
          });

          break;
        }
      }
    };

  return {
    contactMethod,
    to,
    customerFirstName,
    customerLastName,
    error,
    isLoading: isLoadingWithEmailToken || isLoadingWithPhoneToken,
    registerCustomer,
  };
};
