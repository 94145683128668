import React, { useState } from "react";

import { TreatmentHeader } from "../../../therapist-detail-page/components/treatment-header";
import { SwitzerlandTreatmentRow } from "../switzerland-treatment-row";
import { GroupedSwitzerlandTreatments } from "../../types";
import { AddedTreatment } from "../../hooks/use-handle-swiss-treatment";

interface Props {
  treatmentsData: GroupedSwitzerlandTreatments[] | [];
  onAddTreatment: (treatmentUrn: string) => void;
  onRemoveTreatment: (treatmentUrn: string) => void;
  addedTreatment: AddedTreatment;
}

export const SwitzerlandTreatmentTable = ({
  treatmentsData,
  onAddTreatment,
  onRemoveTreatment,
  addedTreatment,
}: Props): JSX.Element => {
  const [descDisplayMap, setDescDisplayMap] = useState<Record<string, boolean>>(
    {},
  );

  const headerHeight = document.querySelector("header")?.offsetHeight ?? 0;
  const therapistTabsHeight =
    document.getElementById("therapist-tabs")?.offsetHeight ?? 0;

  const handleClickTreatmentDetails = (treatmentKey: string): void => {
    const newDescDisplayMap = {
      ...descDisplayMap,
    };

    newDescDisplayMap[treatmentKey] = !newDescDisplayMap[treatmentKey];

    setDescDisplayMap(newDescDisplayMap);
  };

  return (
    <>
      {treatmentsData.map(({ name, urn, treatments }) => (
        <div key={urn}>
          <TreatmentHeader
            name={name}
            categoryUrn={urn}
            topPosition={headerHeight + therapistTabsHeight}
          />
          {treatments.map((therapistTreatment, index) => {
            const addedTreatmentCount =
              addedTreatment[therapistTreatment.urn]?.quantity ?? 0;
            const treatmentKey = `${urn}-${index}`;

            return (
              <SwitzerlandTreatmentRow
                key={treatmentKey}
                treatment={therapistTreatment}
                addedQuantity={addedTreatmentCount}
                isDescriptionShown={Boolean(descDisplayMap[treatmentKey])}
                onClickDetails={handleClickTreatmentDetails.bind(
                  null,
                  treatmentKey,
                )}
                onAdd={onAddTreatment}
                onRemove={onRemoveTreatment}
              />
            );
          })}
        </div>
      ))}
    </>
  );
};
