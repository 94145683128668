import styled from "styled-components";
import { device } from "../../../../utils";

export const ButtonStickyContainer = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 75px;
  margin-bottom: 32px;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.size18};
  z-index: 101;
  pointer-events: none;
  > * {
    pointer-events: auto;
    width: 100%;
  }

  @media ${device.tablet} {
    margin-top: ${({ theme }) => theme.spacing.size24};
    bottom: 50px;
  }
`;
