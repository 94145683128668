import { sortByKey } from "@ruuby/common/lib/utils/sort";

import { TherapistTreatment } from "../../types";
import { TreatmentCategory } from "../../../../types";

interface CategoryGroupedTreatments {
  urn: string;
  name: string;
  treatments: TherapistTreatment[];
}

const sortTherapistTreatments = (
  therapistTreatments: TherapistTreatment[],
): TherapistTreatment[] =>
  therapistTreatments.slice().sort((a, b) => {
    const nameA = a.treatment.name;
    const nameB = b.treatment.name;

    if (nameA < nameB) {
      return -1;
    } else if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

const getTreatmentTopCategories = ({
  categories,
}: TherapistTreatment["treatment"]): TreatmentCategory[] => {
  const categoriesMap: Record<string, TreatmentCategory> = Object.create(null);

  for (const c of categories) {
    let p = c;
    while (p.parent) {
      p = p.parent;
    }

    categoriesMap[p.urn] = {
      urn: p.urn,
      name: p.name,
    };
  }

  return Object.values(categoriesMap);
};

export const groupTherapistTreatments = (
  therapistTreatments: TherapistTreatment[],
  selectedCategoryUrn?: string,
): CategoryGroupedTreatments[] => {
  const res: CategoryGroupedTreatments[] = [];
  const sortedTreatments = sortTherapistTreatments(therapistTreatments);
  const categoriesMap: Record<string, CategoryGroupedTreatments> =
    Object.create(null);

  for (const therapistTreatment of sortedTreatments) {
    const topCategories = getTreatmentTopCategories(
      therapistTreatment.treatment,
    );

    for (const { urn, name } of topCategories) {
      if (!categoriesMap[urn]) {
        categoriesMap[urn] = {
          urn,
          name,
          treatments: [],
        };
      }

      categoriesMap[urn].treatments.push(therapistTreatment);
    }
  }

  if (selectedCategoryUrn && categoriesMap[selectedCategoryUrn]) {
    res.push(categoriesMap[selectedCategoryUrn]);
    delete categoriesMap[selectedCategoryUrn];
  }

  return [...res, ...sortByKey(Object.values(categoriesMap), "name")];
};
