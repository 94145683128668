export interface IConfig {
  version: string;
  api: {
    host: string;
    credentials: {
      id: string;
      secret: string;
    };
  };
  appApi: {
    host: string;
    credentials: {
      deviceId: string;
    };
  };
  e2e: {
    host: string;
  };
  magazineApi: {
    path: string;
  };
  idealPostcodesApi: {
    token: string;
    path: string;
  };
  instagramApi: {
    token: string;
    path: string;
  };
  reCaptcha: {
    sitekey: string;
  };
  dateTime: {
    date: {
      range: {
        days: number;
      };
    };
    time: {
      start: {
        hour: number;
        millisecond: number;
        minute: number;
        second: number;
      };
      end: {
        hour: number;
        millisecond: number;
        minute: number;
        second: number;
      };
      initial: {
        hour: number;
        millisecond: number;
        minute: number;
        second: number;
      };
      interval: {
        minutes: number;
      };
    };
    intervals: {
      am: {
        start: number;
        end: number;
      };
      pm: {
        start: number;
        end: number;
      };
      eve: {
        start: number;
        end: number;
      };
      all: {
        start: number;
        end: number;
      };
    };
    timezone: string;
  };
  analytics: {
    googleTagManagerId: string;
    hotJarId: string;
    segment: {
      writeKey: string;
    };
  };
  postcodeAreas: string[];
  signupPopupTimer: number;
  hubSpot: {
    forms: {
      therapistJoin: string;
    };
  };
  heroVideoUrl: string;
  cookieConsentExpirationDuration: number;
  graphql: {
    url: string;
  };
  specialLink?: string;
  minimumCallOutFee: number;
  affiliates: {
    joinUrl: string;
  };
  payment: {
    applePayMerchantId: string;
    stripePublishableKey: string;
  };
  google: {
    webClientId: string;
  };
}

declare global {
  interface Window {
    RUUBY_CONFIG: IConfig;
  }
}

export const config = {
  ...window.RUUBY_CONFIG,
  version: require("../package.json").version,
};
