import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import {
  fetchUserCreditBalance,
  IPromotion,
} from "../../../../services/api/promotion";

export interface IAddedTreatment {
  urn: string;
  name: string;
  quantity: number;
  price: number;
  duration: number;
}

export interface BookingParams {
  therapistPagePath: string;
  therapistUrn: string;
  therapistName: string;
  // TODO: deprecate this:
  therapistWorkstation: number;
  isTherapistMobile: boolean;
  postcode: string;
  bookingDate: string;
  addedTreatments: IAddedTreatment[];
  treatmentsTotal: number;
  category?: string;
  addressUrn?: string;
  address?: {
    address1: string;
    address2: string;
    postcode: string;
  };
  promo?: IPromotion;
  notes?: string;
}

interface Context {
  bookingParams: BookingParams;
  isBookingBeingPlaced: boolean;
  creditRemaining?: number;
  updateBookingParams: (params: Partial<BookingParams>) => void;
  setIsBookingBeingPlaced: (state: boolean) => void;
  fetchCreditBalance: () => Promise<void>;
}

const BookingCheckoutContext = createContext<Context | null>(null);

const BOOKING_PARAMS_KEY = "BOOKING_PARAMS";

export const serializeBookingParams = (bookingParams: BookingParams): void =>
  sessionStorage.setItem(BOOKING_PARAMS_KEY, JSON.stringify(bookingParams));

export const deserializeBookingParams = (): BookingParams | undefined => {
  const bookingParamsData = sessionStorage.getItem(BOOKING_PARAMS_KEY);

  if (bookingParamsData) {
    return JSON.parse(bookingParamsData);
  }
};

export const clearBookingParams = (): void =>
  sessionStorage.removeItem(BOOKING_PARAMS_KEY);

export const BookingCheckoutProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const history = useHistory();
  const [bookingParams, setBookingParams] = useState<BookingParams | undefined>(
    useMemo(
      () => deserializeBookingParams(),
      [sessionStorage.getItem(BOOKING_PARAMS_KEY)],
    ),
  );
  const [isBookingBeingPlaced, setIsBookingBeingPlaced] = useState(false);
  const [creditRemaining, setCreditRemaining] = useState<number | undefined>();

  if (!bookingParams) {
    //If there is not a check about the pathname we end up in
    //maximum update depth exceeded
    return history.location.pathname !== "/" && history.replace("/");
  }

  const updateBookingParams = useCallback(
    (params: Partial<BookingParams>) => {
      const newBookingParams = {
        ...bookingParams,
        ...params,
      };

      setBookingParams(newBookingParams);
      serializeBookingParams(newBookingParams);
    },
    [bookingParams, setBookingParams],
  );

  const fetchCreditBalance = useCallback(async (): Promise<void> => {
    try {
      const balance = await fetchUserCreditBalance();
      setCreditRemaining(balance);
    } catch (e) {
      console.error("Cannot load user credit balance", e);
    }
  }, [setCreditRemaining]);

  const value: Context = {
    bookingParams,
    isBookingBeingPlaced,
    creditRemaining,
    fetchCreditBalance,
    updateBookingParams,
    setIsBookingBeingPlaced,
  };

  return (
    <BookingCheckoutContext.Provider value={value}>
      {children}
    </BookingCheckoutContext.Provider>
  );
};

export const useBookingCheckoutContext = (): Context => {
  const context = useContext(BookingCheckoutContext);

  if (!context) {
    throw new Error(
      "useBookingCheckoutContext must be used within a BookingCheckoutProvider",
    );
  }

  return context;
};
