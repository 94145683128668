import React, { useMemo } from "react";
import styled from "styled-components";

import { IconButton } from "../../atoms/icon-button";
import { ReactComponent as AppleIcon } from "../../../../assets/svg/apple.svg";
import { ReactComponent as FacebookIcon } from "../../../../assets/svg/facebook.svg";
import { ReactComponent as GoogleIcon } from "../../../../assets/svg/google.svg";

const AppleIconStyled = styled(AppleIcon)`
  width: 16px;
  height: 16px;
`;

const FacebookIconStyled = styled(FacebookIcon)`
  width: 19px;
  height: 19px;
`;

const GoogleIconStyled = styled(GoogleIcon)`
  width: 19px;
  height: 20px;
`;

interface Props {
  type: "Facebook" | "Apple" | "Google";
  shouldShowLoadingIndicator?: boolean;
  disabled?: boolean;
  onClick(): void;
}

export const SingleSignOnButton = ({
  type,
  onClick,
  disabled = false,
}: Props): JSX.Element => {
  const id = useMemo(() => `${type.toLocaleLowerCase()}-sso-button`, [type]);

  return (
    <IconButton
      id={id}
      title={`Continue with ${type}`}
      icon={
        {
          Apple: <AppleIconStyled />,
          Facebook: <FacebookIconStyled />,
          Google: <GoogleIconStyled />,
        }[type]
      }
      onClick={onClick}
      disabled={disabled}
    />
  );
};
