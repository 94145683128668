import { useCallback, useState } from "react";

import {
  ApiError,
  useBookingFeeLazyQuery,
} from "../../../../../../services/api/types/graphql";
import { BookingParams } from "../../provider";

interface UseBookFee {
  fetchBookingFee: (bookingParams: BookingParams) => Promise<void>;
  bookingFee: number;
  bookingFeeDescription: string;
  error?: ApiError;
  isLoading: boolean;
}

export const useBookingFee = () : UseBookFee => {
  const [error, setError] = useState<ApiError>();
  const [bookingFee, setBookingFee] = useState(0);
  const [bookingFeeDescription, setBookingFeeDescription] = useState("");

  const [fetch, { loading: isLoading }] = useBookingFeeLazyQuery({
    onCompleted: async (data) => {
      setBookingFeeDescription(data.bookingFeeDescription);
      switch (data.bookingFee.__typename) {
        case "BookingFee":
          setError(undefined);
          setBookingFee(data.bookingFee.amount);
          break;
        case "RuubyGraphError":
          setError(data.bookingFee.error);
          break;
      }
    },
    onError: () => {
      setError(ApiError.GENERAL_ERROR);
    },
    errorPolicy: "all",
  });

  const fetchBookingFee = useCallback(async (bookingParams: BookingParams ): Promise<void> => {
    setError(undefined);
    await fetch({
      variables: {
        timeStarts: bookingParams.bookingDate,
        therapistUrn: bookingParams.therapistUrn,
        bookingTreatments: bookingParams.addedTreatments.map(({ urn, quantity }) => ({
          urn,
          quantity,
        })),
      },
    })
  }, []);

  return {
    fetchBookingFee,
    isLoading,
    bookingFee,
    bookingFeeDescription,
    ...(error && {
      error,
    }),
  };
};
