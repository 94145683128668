import { useEffect, useState } from "react";

import { GroupedSwitzerlandTreatments } from "../types";
import { zurichTreatments } from "../static";

type useTreatmentData = () => {
  treatmentsData: GroupedSwitzerlandTreatments[] | [];
};

export const useTreatmentData: useTreatmentData = () => {
  const [treatmentsData, setTreatmentData] = useState<
    GroupedSwitzerlandTreatments[] | []
  >([]);

  useEffect(() => {
    setTreatmentData(zurichTreatments);
  }, []);

  return {
    treatmentsData,
  };
};
