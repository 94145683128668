import { useEffect, useState } from "react";
import { fetchConfiguration } from "../../../../../../services/api/configuration";

export interface MarketingRegion {
  label: string;
  value: string;
}

export const useMarketingRegions = (): MarketingRegion[] => {
  const [regions, setRegions] = useState<MarketingRegion[]>([]);

  useEffect(() => {
    fetchConfiguration()
      .then(({ MARKETING_REGIONS: marketingRegions }) => {
        setRegions(marketingRegions);
      });
  }, []);

  return regions;
};