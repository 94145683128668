import React from "react";
import { useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import { useRegisterCustomer } from "../../hooks/use-register-customer";
import { Register } from "../../components/templates/register";
import { ContactMethodType } from "../..";
import { useMarketingRegions } from "../../hooks/use-marketing-regions";

export interface RegisterPageInputs {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  phoneNumber: string;
  agreesToEmailMarketing: boolean;
  preferredRegion?: string;
}

interface RegisterPageProps {
  contactMethod: ContactMethodType;
  onCompleteLogin: () => void;
}

export const RegisterPage = ({
  contactMethod,
  onCompleteLogin,
}: RegisterPageProps): JSX.Element => {
  const { contact, token } = useParams();
  const form = useForm<RegisterPageInputs>();
  const marketingRegions = useMarketingRegions();

  const { isLoading, registerCustomer, error, customerFirstName, customerLastName, to } = useRegisterCustomer({
    contactToken: token,
    onSuccess: onCompleteLogin,
  });

  const onSubmit: SubmitHandler<RegisterPageInputs> = (data) => {
    const { firstName, lastName, phone, email, agreesToEmailMarketing, preferredRegion } = data;
    registerCustomer({
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      agreesToEmailMarketing: agreesToEmailMarketing === true,
      ...(phone && { phone: phone.replace("+", "") }),
      email,
      ...(preferredRegion && {
        preferredRegion,
      }),
    });
  };

  return (
    <Register
      form={form}
      contactMethod={contactMethod}
      contact={to ? to : contact}
      isLoading={isLoading}
      marketingRegions={marketingRegions}
      error={error}
      onSubmit={onSubmit}
      customerFirstName={customerFirstName}
      customerLastName={customerLastName}
    />
  );
};
