import { SwitzerlandTreatment, GroupedSwitzerlandTreatments } from "./types";

const nailsTreatments: SwitzerlandTreatment[] = [
  {
    urn: "urn:ruuby:treatment:0429d009-491d-4557-965d-74d0c77051c4",
    categories: [],
    description: "Relax and enjoy a Bespoke massage – the ultimate in massage therapy. Our expert Therapists will create your very own personalised massage which is mixed of Swedish and Aromatherapy targeted to your individual needs. Begin with a detailed consultation to find out what areas you would like to concentrate on, any problems you are currently experiencing and the type of massage pressure you prefer. Designed to up-lift and re-energise or relax and de-stress will complement your choice of treatment. Please note this treatment is not suitable during pregnancy. We recommend booking a Pregnancy Massage if you have passed your first trimester of pregnancy. If you'd like any further information on what other treatments are suitable during pregnancy/postpartum please get in touch with our Concierge Team at concierge@ruuby.com.",
    name: "Classic Manicure",
    price: {
      classic: 90,
      elite: 100,
    },
  },
  {
    urn: "urn:ruuby:treatment:0654e509-ca02-450e-9fe1-7a5e8c7b46af",
    categories: [],
    name: "Gel Manicure",
    price: {
      classic: 50,
      elite: 60,
    },
  },
  {
    urn: "urn:ruuby:treatment:urn:ruuby:treatment:0810b1fb-b092-4739-ba4e-4c07fa2982f2",
    categories: [],
    description: "Relax and enjoy a Bespoke massage – the ultimate in massage therapy. Our expert Therapists will create your very own personalised massage which is mixed of Swedish and Aromatherapy targeted to your individual needs. Begin with a detailed consultation to find out what areas you would like to concentrate on, any problems you are currently experiencing and the type of massage pressure you prefer. Designed to up-lift and re-energise or relax and de-stress will complement your choice of treatment. Please note this treatment is not suitable during pregnancy. We recommend booking a Pregnancy Massage if you have passed your first trimester of pregnancy. If you'd like any further information on what other treatments are suitable during pregnancy/postpartum please get in touch with our Concierge Team at concierge@ruuby.com.",
    name: "Classic Pedicure",
    price: {
      classic: 40,
      elite: 50,
    },
  },
  {
    urn: "urn:ruuby:treatment:08723b23-a79c-42d2-b81d-f55a054584f9",
    categories: [],
    description: "Relax and enjoy a Bespoke massage – the ultimate in massage therapy. Our expert Therapists will create your very own personalised massage which is mixed of Swedish and Aromatherapy targeted to your individual needs. Begin with a detailed consultation to find out what areas you would like to concentrate on, any problems you are currently experiencing and the type of massage pressure you prefer. Designed to up-lift and re-energise or relax and de-stress will complement your choice of treatment. Please note this treatment is not suitable during pregnancy. We recommend booking a Pregnancy Massage if you have passed your first trimester of pregnancy. If you'd like any further information on what other treatments are suitable during pregnancy/postpartum please get in touch with our Concierge Team at concierge@ruuby.com.",
    name: "Gel Pedicure",
    price: {
      classic: 30,
      elite: 40,
    },
  },
];

const massageTreatments: SwitzerlandTreatment[] = [
  {
    urn: "urn:ruuby:treatment:0aae851d-6d45-4bc0-a981-e5d0ed15fc66",
    categories: [],
    description: "Relax and enjoy a Bespoke massage – the ultimate in massage therapy. Our expert Therapists will create your very own personalised massage which is mixed of Swedish and Aromatherapy targeted to your individual needs. Begin with a detailed consultation to find out what areas you would like to concentrate on, any problems you are currently experiencing and the type of massage pressure you prefer. Designed to up-lift and re-energise or relax and de-stress will complement your choice of treatment. Please note this treatment is not suitable during pregnancy. We recommend booking a Pregnancy Massage if you have passed your first trimester of pregnancy. If you'd like any further information on what other treatments are suitable during pregnancy/postpartum please get in touch with our Concierge Team at concierge@ruuby.com.",
    name: "Deep Tissue Massage - 60 minutes",
    price: {
      classic: 120,
      elite: 150,
    },
  },
  {
    urn: "urn:ruuby:treatment:0ab552a2-f1d3-4974-ba55-5244cab82890",
    categories: [],
    description: "Relax and enjoy a Bespoke massage – the ultimate in massage therapy. Our expert Therapists will create your very own personalised massage which is mixed of Swedish and Aromatherapy targeted to your individual needs. Begin with a detailed consultation to find out what areas you would like to concentrate on, any problems you are currently experiencing and the type of massage pressure you prefer. Designed to up-lift and re-energise or relax and de-stress will complement your choice of treatment. Please note this treatment is not suitable during pregnancy. We recommend booking a Pregnancy Massage if you have passed your first trimester of pregnancy. If you'd like any further information on what other treatments are suitable during pregnancy/postpartum please get in touch with our Concierge Team at concierge@ruuby.com.",
    name: "Sports Massage - 120 minutes",
    price: {
      classic: 170,
      elite: 180,
    },
  },
];

export const zurichTreatments: GroupedSwitzerlandTreatments[] = [
  {
    name: "Nails",
    urn: "urn:ruuby:treatment:038b6ad3-01c5-49da-856c-c805cdc85135",
    treatments: nailsTreatments,
  },
  {
    name: "Massage",
    urn: "urn:ruuby:treatment:08e77797-14a2-45ce-9990-93cfb20930be",
    treatments: massageTreatments,
  },
];
