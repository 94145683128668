import { useEffect, useState } from "react";
import {
  getCreditsDeduction,
  getPromotionDeduction,
} from "@ruuby/common/lib/utils/bookings";

import { useBookingFee } from "../use-booking-fee";
import {
  PaymentServiceType,
  useGetPaymentServiceType,
} from "../../../../../../hooks/use-get-payment-service-type";
import { useBookingCheckoutContext } from "../../provider";

type UseBookingTotal = () => {
  isLoading: boolean;
  subTotal: number;
  total: number;
  bookingFee: number;
  creditsDeduction?: number;
  promotionDeduction?: number;
};

export const useBookingTotal: UseBookingTotal = () => {
  const { bookingParams, creditRemaining, fetchCreditBalance } = useBookingCheckoutContext();
  const paymentProviderType = useGetPaymentServiceType();
  const {
    bookingFee,
    fetchBookingFee,
    isLoading: isLoadingFee,
  } = useBookingFee();
  const [total, setTotal] = useState(0);
  const [creditsDeduction, setCreditsDeduction] = useState(0);
  const [promotionDeduction, setPromotionDeduction] = useState(0);

  useEffect(() => {
    fetchCreditBalance().catch(console.error);
  }, [fetchCreditBalance]);

  useEffect(() => {
      fetchBookingFee(bookingParams).catch(console.error);
  }, [bookingParams]);

  useEffect(() => {
    if (typeof creditRemaining !== "undefined") {
      const { treatmentsTotal, promo } = bookingParams;

      if (paymentProviderType === PaymentServiceType.STRIPE) {
        const promotionDeduction = getPromotionDeduction(
          treatmentsTotal,
          promo,
        );
        const creditsDeduction = getCreditsDeduction(
          treatmentsTotal - promotionDeduction,
          creditRemaining,
        );
        const total =
          treatmentsTotal - promotionDeduction - creditsDeduction + bookingFee;

        setPromotionDeduction(promotionDeduction);
        setCreditsDeduction(creditsDeduction);
        setTotal(total);
      } else if (paymentProviderType === PaymentServiceType.BRAINTREE) {
        const creditsDeduction = getCreditsDeduction(
          treatmentsTotal,
          creditRemaining,
        );
        const promotionDeduction = getPromotionDeduction(
          treatmentsTotal - creditsDeduction,
          promo,
        );
        const total =
          treatmentsTotal - creditsDeduction - promotionDeduction + bookingFee;

        setCreditsDeduction(creditsDeduction);
        setPromotionDeduction(promotionDeduction);
        setTotal(total);
      }
    }
  }, [
    bookingParams.treatmentsTotal,
    bookingParams.promo,
    bookingFee,
    creditRemaining,
    paymentProviderType,
  ]);

  return {
    isLoading:
      isLoadingFee || typeof creditRemaining === "undefined" || !total,
    subTotal: bookingParams.treatmentsTotal,
    total,
    bookingFee,
    creditsDeduction,
    promotionDeduction,
  };
};
