import * as H from "history";
import * as React from "react";
import { Redirect, Route, Switch } from "react-router";

import {
  AboutPage,
  AccountPage,
  AddressPage,
  BridalPage,
  CategoryPage,
  FaqsPage,
  FeaturedTreatmentsPage,
  JobDetailPage,
  LandingProxyPage,
  NotFoundPage,
  PrivacyPolicyPage,
  RatingPage,
  TermsAndConditionsPage,
} from "../components/pages";
import { RedirectHelper } from "../components/utils";
import {
  CategoryLandingPage,
  JobListingPage,
  HomePage,
  LoginPage,
  TherapistListingPage,
  EventsPage,
  CorporatePage,
  CheckoutPage,
  BookingConfirmationPage,
  SouthOfFrancePage,
} from "../components/themed-v2/pages";
import { MainTemplate } from "../components/templates/main";
import { PaymentRequestPage } from "../components/themed-v2/pages/payment-request-page";
import { TherapistDetailPage } from "../components/themed-v2/pages/therapist-detail-page";
import { AffiliatesPage } from "../components/themed-v2/pages/affiliates-page";
import { config } from "../config";
import { SwitzerlandLandingPage } from "../components/themed-v2/pages/switzerland-landing-page";
import { SwitzerlandPage } from "../components/themed-v2/pages/switzerland";
import { IAppConfiguration } from "../services/api/configuration";

export const appRoutes = (
  history: H.History,
  configuration: IAppConfiguration,
) => (
  <Switch key="switch">
    <Route exact path="/(referral-return)?">
      <MainTemplate>
        <HomePage />
      </MainTemplate>
    </Route>
    <Route
      path="/category"
      render={(props) => <CategoryPage path={props.match.path} />}
    />
    <Route exact path="/book" render={() => <TherapistListingPage />} />
    <Route
      exact
      path="/landing/:categoryName/:districtCode"
      render={() => <LandingProxyPage />}
    />
    <Route
      exact
      path="/mobile-beauty/:categoryName/"
      render={() => (
        <MainTemplate>
          <CategoryLandingPage history={history} />
        </MainTemplate>
      )}
    />
    <Route
      path="/featured-treatments/:featuredListUrn"
      render={() => <FeaturedTreatmentsPage />}
    />
    <Route path="/address" render={() => <AddressPage />} />
    <Route
      exact
      path="/book/:therapistId/:category?"
      render={() => <TherapistDetailPage history={history} />}
    />
    <Route
      path="/booking/confirmation"
      render={() => <BookingConfirmationPage />}
    />
    <Route path="/booking" render={() => <CheckoutPage />} />
    <Route path="/payment/:token" render={() => <PaymentRequestPage />} />
    <Route exact path="/account" render={() => <AccountPage />} />
    <Redirect exact path="/login" to="/login/phone" />
    <Route path="/login/:type" render={() => <LoginPage />} />
    <Route
      path={"/auth/:method/:contact/:token"}
      render={() => <LoginPage />}
    />
    <Redirect exact path="/black-label" to="/about" />
    <Route exact path="/about" render={() => <AboutPage />} />
    <Route exact path="/privacy-policy" render={() => <PrivacyPolicyPage />} />
    <Route
      exact
      path="/terms_and_conditions"
      render={() => <TermsAndConditionsPage />}
    />
    <Route exact path="/faqs" render={() => <FaqsPage />} />
    <Redirect exact path="/signup" to="/signup/phone" />
    <Route path="/signup/:type" render={() => <LoginPage />} />
    <Route
      exact
      path="/jobs"
      render={() => (
        <MainTemplate>
          <JobListingPage history={history} />
        </MainTemplate>
      )}
    />
    <Route
      exact
      path="/jobs/:id"
      render={(props) => <JobDetailPage id={props.match.params.id} />}
    />
    <Route
      exact
      path="/rate/:bookingId"
      render={(props) => (
        <RatingPage
          bookingId={props.match.params.bookingId}
          history={history}
        />
      )}
    />
    <Route path="/bridal-hair-and-makeup" render={() => <BridalPage />} />
    <Route path="/events" render={() => <EventsPage />} />
    <Route path="/corporate" render={() => <CorporatePage />} />
    <Route path="/south-of-france" render={() => <SouthOfFrancePage />} />
    <Route
      path="/switzerland"
      render={() =>
        configuration.SHOW_SWITZERLAND_LANDING_PAGE ? (
          <SwitzerlandLandingPage />
        ) : (
          <SwitzerlandPage />
        )
      }
    />
    <Route
      path="/app/switzerland"
      render={() => <SwitzerlandLandingPage isWebView={true} />}
    />
    {Boolean(config.affiliates?.joinUrl) && (
      <Route
        path="/affiliates"
        render={() => (
          <MainTemplate>
            <AffiliatesPage />
          </MainTemplate>
        )}
      />
    )}
    <Route exact path="/404" render={() => <NotFoundPage />} />
    <RedirectHelper />
  </Switch>
);
