import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import jQuery from "jquery";

import { IAppConfiguration } from "../../services/api/configuration";
import { HeaderBar, MobileMenu, Navigation } from "../../components/organisms";
import { ICategory } from "../../services/api/category-list";
import { IRootState } from "../../store/index";
import { ILoginUser } from "../../services/api/customer";
import { isMobileWidth, noNavPages } from "../../utils/index";

import { useDownloadAppLink } from "../../hooks/use-download-app-link";
import { config } from "../../config";

interface IState {
  isAppStoreModalOpen: boolean;
  isMobile: boolean;
  isMobileMenuOpen: boolean;
}

interface ReduxProps {
  categories: ICategory[];
  isLogedIn: boolean;
  user?: ILoginUser;
  configuration?: IAppConfiguration;
}

interface OwnProps {
  onClickDownloadApp: () => void;
}

type RouteProps = RouteComponentProps<{
  categoryName: string;
  therapistId: string;
}>;

type Props = ReduxProps & OwnProps & RouteProps & { dispatch: Dispatch<any> };

class HeaderContainerComponent extends React.PureComponent<Props, IState> {
  public state = {
    isAppStoreModalOpen: false,
    isMobile: false,
    isMobileMenuOpen: false,
  };

  private readonly clearMobileMenu = () => {
    this.setState({ isMobileMenuOpen: false }, () => {
      jQuery("body").removeAttr("style");
    });
  };

  private readonly toggleIsMobile = (isMobile: boolean): void => {
    isMobile
      ? this.setState({ isMobile: true })
      : this.setState({ isMobile: false });

    if (this.state.isMobileMenuOpen) {
      this.clearMobileMenu();
    }
  };

  public componentDidMount() {
    isMobileWidth(this.toggleIsMobile);
    window.addEventListener("resize", () => isMobileWidth(this.toggleIsMobile));
  }

  public componentWillUnmount(): void {
    window.removeEventListener("resize", () =>
      isMobileWidth(this.toggleIsMobile),
    );
  }

  public toggleMobileMenu = (): void => {
    if (this.state.isMobileMenuOpen) {
      this.clearMobileMenu();
    } else {
      this.setState({ isMobileMenuOpen: true }, () => {
        jQuery("body").css("overflow", "hidden");
      });
    }
  };

  /**
   * Render main navigation bar with categories
   */
  public render() {
    return (
      <>
        <>
          <HeaderBar
            isMobile={this.state.isMobile}
            location={this.props.location.pathname}
            onDownload={this.props.onClickDownloadApp}
            onShowMobileMenu={this.toggleMobileMenu}
          />
          {!noNavPages(this.props.location.pathname) &&
            !this.state.isMobile && (
              <Navigation
                categories={this.props.categories}
                isLoggedIn={this.props.isLogedIn}
                user={this.props.user}
                specialLink={config.specialLink}
              />
            )}
          <MobileMenu
            isMobileMenuOpen={this.state.isMobileMenuOpen}
            categories={this.props.categories}
            isLoggedIn={this.props.isLogedIn}
            onClose={this.toggleMobileMenu}
            onDownload={this.props.onClickDownloadApp}
            user={this.props.user}
            specialLink={config.specialLink}
          />
        </>
      </>
    );
  }
}

// store
const mapStateToProps = (state: IRootState): ReduxProps => {
  return {
    categories: state.categoriesState.categories,
    isLogedIn: state.userState.isLogedIn,
    user: state.userState.user,
    configuration: state.configurationState.configuration,
  };
};

const ConnectedHeaderContainerComponent = withRouter(
  connect<ReduxProps, {}, {}, IRootState>(mapStateToProps)(
    HeaderContainerComponent,
  ),
);

export const HeaderContainer = (): JSX.Element => {
  const { QrModal, handleClickDownloadApp } = useDownloadAppLink();

  return (
    <>
      <ConnectedHeaderContainerComponent
        onClickDownloadApp={handleClickDownloadApp}
      />
      <QrModal />
    </>
  );
};
